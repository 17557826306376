
import React from 'react';




const Ranking = ({ fullDetailUniversities, showOnlyVerified, toggleUniversityDetails, isMobile }) => {
  return (


    <div style={{ width: '100%' }}>


      {fullDetailUniversities.slice(0, 100).map((uni, index) => (

        <div key={index} onClick={() => toggleUniversityDetails(index)}>

          {uni.showDetails ? (

            <div className='uniItem_1_1'>


              <div style={{ alignContent: 'top', gridColumn: '1', display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row', alignItems: 'flex-start' }}>
                <div style={{ height: '100%', flex: 3, minWidth: '60%', display: 'flex', width: '100%', flexDirection: 'column', alignContent: 'space-between' }}>

                  {isMobile ? (
                    <div className='centeredVertically' style={{ minWidth: '60%', display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>


                      <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'left', flexDirection: 'row' }}>

                        <h4 style={{ marginRight: '15px', whiteSpace: 'nowrap' }}># {index + 1}</h4>
                        <p className='badge' style={{ marginRight: '15px' }}>
                          {uni.averageRating.toFixed(1)}
                        </p>
                        <h3 style={{ marginRight: '15px' }}>{uni.name}</h3>


                      </div>


                      <img
                        src="/images/minus.png"
                        alt="Collapse"
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                      />


                    </div>

                  ) : (
                    <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
                      <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'left', width: '100%', flexDirection: 'row' }}>

                        <h4 style={{ marginRight: '30px', whiteSpace: 'nowrap' }}># {index + 1}</h4>
                        <p className='badge' style={{ marginRight: '30px' }}>
                          {uni.averageRating.toFixed(1)}
                        </p>
                        <h3 style={{ marginRight: '30px' }}>{uni.name}</h3>





                      </div>

                      {showOnlyVerified ? (
                        <p className='reviewCount' style={{ whiteSpace: 'nowrap', marginRight: '30px' }}>{Math.round(uni.verifiedCount / 7)} {Math.round(uni.verifiedCount / 7) < 2 ? "verifizierte Bewertung" : "verifizierte Bewertungen"}</p>
                      ) : (
                        <p className='reviewCount' style={{ whiteSpace: 'nowrap', marginRight: '30px' }}>{Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)} {(Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)) < 2 ? "Bewertung" : "Bewertungen"}</p>
                      )
                      }

                      <img
                        src="/images/minus.png"
                        alt="Collapse"
                        className="centered-icon"
                        style={{ width: '20px', height: '20px' }}
                      />
                    </div>

                  )}





                </div>
              </div>


              <div className='uniItemDetails'>
                {uni.categories && uni.categories
                  .slice()
                  .sort((a, b) => a.position - b.position)
                  .map((category, catIndex) => (
                    <div className='centered' key={catIndex} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', width: '100%', margin: '4px', padding: '0' }}>
                      <p style={{ margin: '0', textAlign: 'right' }}>{category.category}</p>

                      <div style={{
                      }}>

                        <div className="progress-container" style={{
                          width: '100%',
                          height: '8px',
                          margin: '0',
                          padding: '0',
                          backgroundColor: '#fff', // White background for the non-progressed part
                          outline: '3px solid #222', // Black border
                          borderRadius: '10px' // Optional: Rounded corners for the whole bar
                        }}>
                          <div className="progress-bar" style={{
                            width: `${category.averageRating ? category.averageRating * 1 : 0}%`,
                            height: '100%',
                            margin: '0',
                            padding: '0',
                            backgroundColor: '#222', // Black fill for the progressed part
                            borderRadius: category.averageRating >= 99 ? '0px' : '0px 0 0 0px', // Inherit the border radius from the container
                          }}>
                          </div>
                        </div>
                      </div>

                    </div>
                  ))}
              </div>



              {isMobile && (
                <div name='voteCount' style={{ display: 'flex', justifyContent: 'right', marginTop: 'auto' }}>
                  {showOnlyVerified ? (
                    <p className='reviewCount'>{Math.round(uni.verifiedCount / 7)} {Math.round(uni.verifiedCount / 7) < 2 ? "verifizierte Bewertung" : "verifizierte Bewertungen"}</p>
                  ) : (
                    <p className='reviewCount'>{Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)} {(Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)) < 2 ? "Bewertung" : "Bewertung"}</p>
                  )
                  }
                </div>
              )}



            </div>
          ) : (


            <div className='uniItem_1_2'>
              {isMobile ? (

                <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'column' }}>

                  <div className='centeredVertically' style={{ flex: 3, display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>

                    <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'left', flexDirection: 'row' }}>
                      <h4 style={{ marginRight: '15px', whiteSpace: 'nowrap' }}># {index + 1}</h4>
                      <p className='badge' style={{ marginRight: '15px' }}>
                        {uni.averageRating.toFixed(1)}
                      </p>
                      <h3 style={{ marginRight: '15px' }}>{uni.name}</h3>
                    </div>

                    <img
                      src="/images/plus.png"
                      alt="Expand"
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />

                  </div>

                  <div style={{ flex: 1, display: 'flex', justifyContent: 'right', width: '100%' }}>
                    {showOnlyVerified ? (
                      <p className='reviewCount' >{Math.round(uni.verifiedCount / 7)} {Math.round(uni.verifiedCount / 7) < 2 ? "verifizierte Bewertung" : "verifizierte Bewertungen"}</p>
                    ) : (
                      <p className='reviewCount'>{Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)} {(Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)) < 2 ? "Bewertung" : "Bewertungen"}</p>
                    )
                    }

                  </div>

                </div>

              ) : (
                <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row'}}>

                  <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'left', width: '100%', flexDirection: 'row' }}>

                    <h4 style={{ marginRight: '30px', whiteSpace: 'nowrap' }}># {index + 1}</h4>
                    <p className='badge' style={{ marginRight: '30px' }}>
                      {uni.averageRating.toFixed(1)}
                    </p>
                    <h3 style={{ marginRight: '30px', width: 'auto' }}>{uni.name}</h3>


                  </div>

                  {showOnlyVerified ? (
                    <p className='reviewCount' style={{ whiteSpace: 'nowrap', marginRight: '30px' }} >{Math.round(uni.verifiedCount / 7)} {Math.round(uni.verifiedCount / 7) < 2 ? "verifizierte Bewertung" : "verifizierte Bewertungen"}</p>
                  ) : (
                    <p className='reviewCount' style={{ whiteSpace: 'nowrap', marginRight: '30px' }}>{Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)} {(Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)) < 2 ? "Bewertung" : "Bewertungen"}</p>
                  )
                  }

                  <div style={{ flex: 1, display: 'flex', justifyContent: 'right' }}>
                    <img
                      src="/images/plus.png"
                      alt="Expand"
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </div>

                </div>
              )}

            </div>


          )


          }

        </div>

      ))}

    </div>

  )
};

export default Ranking;










