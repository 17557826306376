


import React from 'react';
import Slider from '@mui/material/Slider';





const MatchQuestions1 = ({ categoriesMatchingSpecial, handleFindMatchClick, sliderValuesMatching, handleMatchSliderChange, isMobile

}) => {
    return (

        <div>


            <h3>Wie sollen die Studierenden drauf sein?</h3>

            <div className='matchingQuestions'>
                {categoriesMatchingSpecial.map(category => (

                    <div key={category.id}>


                        {isMobile ? (

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <Slider
                                    value={sliderValuesMatching[category.id]}
                                    onChange={(e) => handleMatchSliderChange(category.id, e.target.value)}
                                    onChangeCommitted={handleFindMatchClick}
                                    defaultValue={50} // Set the default value directly here
                                    min={0} // Minimum value of the slider
                                    max={100} // Maximum value of the slider
                                    sx={{

                                        '& .MuiSlider-thumb': {
                                            width: 24, // Example size
                                            height: 24, // Example size
                                            backgroundColor: 'black', // Example color
                                            transition: 'none',  // Disables the animation

                                            // '&:hover, &:focus, &.Mui-focusVisible': { // Styles for hover and focus
                                            //     boxShadow: `0px 0px 0px 7px var(--c-slidershaddow)`, // shadow
                                            // },
                                            // '&.Mui-active': { // Styles for the thumb when active (clicked and dragged)
                                            //     boxShadow: `0px 0px 0px 10px var(--c-slidershaddow2)`, // Change the color and size of the shadow as needed
                                            // }
                                        },
                                        '& .MuiSlider-track': {
                                            height: 5, // Example height
                                            backgroundColor: '#000', // Example color
                                            position: 'relative', // Needed for positioning pseudo-elements
                                            border: '2px solid black', // Border around the entire slider
                                            transition: 'none',  // Disables the animation


                                            '&::before, &::after': { // Pseudo-elements for the borders
                                                content: '""', // Required for pseudo-elements
                                                position: 'absolute',
                                                height: '100%',
                                                width: 2, // Width of the border
                                                backgroundColor: 'black', // Border color
                                            },
                                            '&::before': {
                                                left: 0, // Position at the start of the track
                                            },
                                            '&::after': {
                                                right: 0, // Position at the end of the track

                                            }
                                        },
                                        '& .MuiSlider-rail': {
                                            height: 8, // Example height
                                            backgroundColor: 'black', // Example color
                                            opacity: '1',
                                            border: '3px solid black', // Border around the entire slider
                                            transition: 'none',  // Disables the animation

                                        },
                                    }}
                                />



                                <div style={{ display: 'flex', width:'100%',marginTop:'0px', justifyContent:'space-between' }}>
                                    <span className='matchingQuestionMarks' style={{ textAlign: 'left'}}>{category.marks[0].label}</span> 
                                    <span className='matchingQuestionMarks' style={{ textAlign: 'right'}}>{category.marks[1].label}</span>

                                </div>

                            </div>


                        ) : (
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr', gap: '2vw', alignItems: 'center' }}>
                                <span className='matchingQuestionMarks' style={{ textAlign: 'right' }}>{category.marks[0].label}</span> {/* Label for min value */}

                                <Slider
                                    value={sliderValuesMatching[category.id]}
                                    onChange={(e) => handleMatchSliderChange(category.id, e.target.value)}
                                    onChangeCommitted={handleFindMatchClick}
                                    defaultValue={50} // Set the default value directly here
                                    min={0} // Minimum value of the slider
                                    max={100} // Maximum value of the slider
                                    sx={{

                                        '& .MuiSlider-thumb': {
                                            width: 24, // Example size
                                            height: 24, // Example size
                                            backgroundColor: 'black', // Example color

                                            '&:hover, &:focus, &.Mui-focusVisible': { // Styles for hover and focus
                                                boxShadow: `0px 0px 0px 7px var(--c-slidershaddow)`, // shadow
                                            },
                                            '&.Mui-active': { // Styles for the thumb when active (clicked and dragged)
                                                boxShadow: `0px 0px 0px 10px var(--c-slidershaddow2)`, // Change the color and size of the shadow as needed
                                            }
                                        },
                                        '& .MuiSlider-track': {
                                            height: 5, // Example height
                                            backgroundColor: '#000', // Example color
                                            position: 'relative', // Needed for positioning pseudo-elements
                                            border: '2px solid black', // Border around the entire slider


                                            '&::before, &::after': { // Pseudo-elements for the borders
                                                content: '""', // Required for pseudo-elements
                                                position: 'absolute',
                                                height: '100%',
                                                width: 2, // Width of the border
                                                backgroundColor: 'black', // Border color
                                            },
                                            '&::before': {
                                                left: 0, // Position at the start of the track
                                            },
                                            '&::after': {
                                                right: 0, // Position at the end of the track

                                            }
                                        },
                                        '& .MuiSlider-rail': {
                                            height: 8, // Example height
                                            backgroundColor: 'black', // Example color
                                            opacity: '1',
                                            border: '3px solid black', // Border around the entire slider

                                        },
                                    }}
                                />
                                <span className='matchingQuestionMarks' style={{ textAlign: 'left' }}>{category.marks[1].label}</span>
                            </div>




                        )}

                    </div>


                ))}

            </div>

        </div>



    )
};

export default MatchQuestions1;