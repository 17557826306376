


import React from 'react';
import Slider from '@mui/material/Slider';





const Questions2 = ({ searchResults, handleUserInputChange, isMobile

}) => {
    return (


        <div className='evaluationItem' style={{ marginTop: '50px' }}>

            {searchResults
            .slice() 
            .sort((a, b) => a.position - b.position) 
            .map((result, index) => (
                <div key={result.id} className='evaluationItemContent' style={{ marginBottom: '30px' }}>

                    <h3 style={{ marginBottom: '0', lineHeight: '1.5', padding: '0', margin: '0' }}>
                        {result.text}
                    </h3>


                    {isMobile ? (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column', alignItems: 'center',
                            width: '80%',
                            marginTop: '-5px'
                        }}>


                            <Slider
                                value={result.userInput}
                                onChange={(e) => handleUserInputChange(result.id, e.target.value)}
                                defaultValue={50} // Set the default value directly here
                                min={0} // Minimum value of the slider
                                max={100} // Maximum value of the slider
                                sx={{

                                    '& .MuiSlider-thumb': {
                                        width: 24, // Example size
                                        height: 24, // Example size
                                        backgroundColor: 'black', // Example color
                                        transition: 'none',  // Disables the animation

                                        // '&:hover, &:focus, &.Mui-focusVisible': { // Styles for hover and focus
                                        //     boxShadow: `0px 0px 0px 7px var(--c-slidershaddow)`, // Yellow shadow
                                        // },
                                        // '&.Mui-active': { // Styles for the thumb when active (clicked and dragged)
                                        //     boxShadow: `0px 0px 0px 10px var(--c-slidershaddow2)`, // Change the color and size of the shadow as needed
                                        // }
                                    },
                                    '& .MuiSlider-track': {
                                        height: 5, // Example height
                                        backgroundColor: '#000', // Example color
                                        position: 'relative', // Needed for positioning pseudo-elements
                                        border: '2px solid black', // Border around the entire slider
                                        transition: 'none',  // Disables the animation


                                        '&::before, &::after': { // Pseudo-elements for the borders
                                            content: '""', // Required for pseudo-elements
                                            position: 'absolute',
                                            height: '100%',
                                            width: 2, // Width of the border
                                            backgroundColor: 'black', // Border color
                                        },
                                        '&::before': {
                                            left: 0, // Position at the start of the track
                                        },
                                        '&::after': {
                                            right: 0, // Position at the end of the track

                                        }
                                    },
                                    '& .MuiSlider-rail': {
                                        height: 8, // Example height
                                        backgroundColor: 'white', // Example color
                                        opacity: '1',
                                        border: '4px solid black', // Border around the entire slider
                                        transition: 'none',  // Disables the animation


                                    },
                                }}
                            />

                            <div style={{ display: 'flex', width: '100%', marginTop: '5px', justifyContent: 'space-between' }}>
                                <span className='evaluationMarks' style={{ textAlign: 'right' }}>{result.marks[0].label}</span>
                                <span className='evaluationMarks' style={{ textAlign: 'left' }}>{result.marks[1].label}</span>

                            </div>


                        </div>



                    ) : (


                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 2fr 1fr',
                            gap: '2vw',
                            alignItems: 'center',
                        }}>
                            <span className='evaluationMarks' style={{ textAlign: 'right' }}>{result.marks[0].label}</span> {/* Label for min value */}

                            <Slider
                                value={result.userInput}
                                onChange={(e) => handleUserInputChange(result.id, e.target.value)}
                                defaultValue={50} // Set the default value directly here
                                min={0} // Minimum value of the slider
                                max={100} // Maximum value of the slider
                                sx={{

                                    '& .MuiSlider-thumb': {
                                        width: 24, // Example size
                                        height: 24, // Example size
                                        backgroundColor: 'black', // Example color

                                        '&:hover, &:focus, &.Mui-focusVisible': { // Styles for hover and focus
                                            boxShadow: `0px 0px 0px 7px var(--c-slidershaddow)`, // Yellow shadow
                                        },
                                        '&.Mui-active': { // Styles for the thumb when active (clicked and dragged)
                                            boxShadow: `0px 0px 0px 10px var(--c-slidershaddow2)`, // Change the color and size of the shadow as needed
                                        }
                                    },
                                    '& .MuiSlider-track': {
                                        height: 5, // Example height
                                        backgroundColor: '#000', // Example color
                                        position: 'relative', // Needed for positioning pseudo-elements
                                        border: '2px solid black', // Border around the entire slider


                                        '&::before, &::after': { // Pseudo-elements for the borders
                                            content: '""', // Required for pseudo-elements
                                            position: 'absolute',
                                            height: '100%',
                                            width: 2, // Width of the border
                                            backgroundColor: 'black', // Border color

                                        },
                                        '&::before': {
                                            left: 0, // Position at the start of the track
                                        },
                                        '&::after': {
                                            right: 0, // Position at the end of the track

                                        }
                                    },
                                    '& .MuiSlider-rail': {
                                        height: 8, // Example height
                                        backgroundColor: 'white', // Example color
                                        opacity: '1',
                                        border: '4px solid black', // Border around the entire slider


                                    },
                                }}
                            />
                            <span className='evaluationMarks' style={{ textAlign: 'left' }}>{result.marks[1].label}</span>
                        </div>

                    )}

                </div>
            ))}

        </div>



    )
};

export default Questions2;