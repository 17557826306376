


import React, { useState, useEffect } from 'react';




const EmailField = ({ emailInputValue, handleEmailInputChange, handleEmailBlur, handleEmailBlur2, selectedUniversityData, emailWarning, isMobile
  
}) => {
  const [debounceTimeoutId, setDebounceTimeoutId] = useState(null);


  const _handleEmailInputChange = (e) => {

    handleEmailInputChange(e);
    
    // Clear the previous timeout if the user types again within 1 second
    if (debounceTimeoutId) clearTimeout(debounceTimeoutId);

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      handleEmailBlur2(e.target.value);
    }, 1500); // delay

    setDebounceTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeoutId) clearTimeout(debounceTimeoutId);
    };
  }, [debounceTimeoutId]);


  
  const _mhandleEmailInputChange = (e) => {
    handleEmailInputChange(e);    
  };

  return (

    <div>


      {
        isMobile ? (

          <div style={{ display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '30px' }} >
            <h3 style={{ minWidth: '150px', textAlign: 'center', maxWidth:'80%', margin:'20px' }}>
              Deine Uni-Mail zur Verifizierung:
            </h3>
            <div className='emailInputContainer' style={{ minWidth: '150px',maxWidth: '90%' }}>
              <input
                className='emailInputBar'
                type="email"
                name="email"
                style={{ width: '100%' }}
                value={emailInputValue}
                onChange={_mhandleEmailInputChange}
                onBlur={handleEmailBlur}
                placeholder={`...@...${selectedUniversityData.mail_server}`}
                autoComplete="email"
              />
            </div>
            <div className='centered' style={{ minWidth: '150px', maxWidth:'80%', margin:'20px' }}>
              {emailWarning && <span className='emailWarning'>{emailWarning}</span>}
            </div>
          </div >



        ) : (

          <div className='centered' style={{ display: 'grid', gap: '30px 50px', gridTemplateColumns: '20vw 25vw', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '100px' }} >
            <h3 style={{fontSize:'16px',  flex: 'none', minWidth: '150px', gridColumn: '1', textAlign: 'right' }}>
              Deine Uni-Mail zur Verifizierung:
            </h3>
            <div className='emailInputContainer' style={{ gridColumn: '2', minWidth: '150px' }}>
              <input
                className='emailInputBar'
                type="email"
                name="email"
                style={{ width: '100%' }}
                value={emailInputValue}
                onChange={_handleEmailInputChange}
                onBlur={handleEmailBlur}
                placeholder={`...@...${selectedUniversityData.mail_server}`}
                autoComplete="email"

              />
            </div>
            <div className='centered' style={{ gridColumn: '2', minWidth: '150px' }}>
              {emailWarning && <span className='emailWarning'>{emailWarning}</span>}
            </div>
          </div >





        )}


    </div>







  )
};

export default EmailField;