



import React from 'react';
import Slider from '@mui/material/Slider';



const MatchQuestions2 = ({ categoriesMatching, sliderValuesMatching, handleMatchSliderChange, handleFindMatchClick, isMobile

}) => {
  return (





    <div>

      <h3 style={{ marginTop: '50px' }}>Was ist dir wie wichtig?</h3>

      <div className='matchingQuestions' style={{ marginTop: '20px'}}>
        {categoriesMatching
        .slice() 
        .sort((a, b) => a.position - b.position) 
        .map(category => (

          <div key={category.id}>
            {isMobile ? (

              <div  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop:'10px',marginBottom:'10px' }}>


                <label style={{ textAlign: 'left', color:'black' }}>{category.question_matching}:</label>


                <Slider
                  value={sliderValuesMatching[category.id]}
                  onChange={(e) => handleMatchSliderChange(category.id, e.target.value)}
                  onChangeCommitted={handleFindMatchClick} // Use onChangeCommitted for the drag commit action
                  defaultValue={50} // Set the default value directly here
                  min={0} // Minimum value of the slider
                  max={100} // Maximum value of the slider
                  sx={{

                    '& .MuiSlider-thumb': {
                      width: 24, // Example size
                      height: 24, // Example size
                      backgroundColor: 'black', // Example color
                      transition: 'none',  // Disables the animation

                      // '&:hover, &:focus, &.Mui-focusVisible': { // Styles for hover and focus
                      //   boxShadow: `0px 0px 0px 7px var(--c-slidershaddow)`, // Yellow shadow
                      // },
                      // '&.Mui-active': { // Styles for the thumb when active (clicked and dragged)
                      //   boxShadow: `0px 0px 0px 10px var(--c-slidershaddow2)`, // Change the color and size of the shadow as needed
                      // }
                    },
                    '& .MuiSlider-track': {
                      height: 5, // Example height
                      backgroundColor: '#000', // Example color
                      position: 'relative', // Needed for positioning pseudo-elements
                      border: '2px solid black', // Border around the entire slider
                      transition: 'none',  // Disables the animation


                      '&::before, &::after': { // Pseudo-elements for the borders
                        content: '""', // Required for pseudo-elements
                        position: 'absolute',
                        height: '100%',
                        width: 2, // Width of the border
                        backgroundColor: 'black', // Border color
                        transition: 'none',  // Disables the animation

                      },
                      '&::before': {
                        left: 0, // Position at the start of the track
                      },
                      '&::after': {
                        right: 0, // Position at the end of the track

                      }
                    },
                    '& .MuiSlider-rail': {
                      height: 8, // Example height
                      backgroundColor: 'white', // Example color
                      opacity: '1',
                      border: '3px solid black', // Border around the entire slider
                      transition: 'none',  // Disables the animation


                    },
                  }}
                />



              </div>


            ) : (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px', marginBottom: '10px', alignItems: 'center' }}>
                <label style={{ textAlign: 'right' }}>{category.question_matching}</label>

                <Slider
                  value={sliderValuesMatching[category.id]}
                  onChange={(e) => handleMatchSliderChange(category.id, e.target.value)}
                  onChangeCommitted={handleFindMatchClick} // Use onChangeCommitted for the drag commit action
                  defaultValue={50} // Set the default value directly here
                  min={0} // Minimum value of the slider
                  max={100} // Maximum value of the slider
                  sx={{

                    '& .MuiSlider-thumb': {
                      width: 24, // Example size
                      height: 24, // Example size
                      backgroundColor: 'black', // Example color

                      '&:hover, &:focus, &.Mui-focusVisible': { // Styles for hover and focus
                        boxShadow: `0px 0px 0px 7px var(--c-slidershaddow)`, // Yellow shadow
                      },
                      '&.Mui-active': { // Styles for the thumb when active (clicked and dragged)
                        boxShadow: `0px 0px 0px 10px var(--c-slidershaddow2)`, // Change the color and size of the shadow as needed
                      }
                    },
                    '& .MuiSlider-track': {
                      height: 5, // Example height
                      backgroundColor: '#000', // Example color
                      position: 'relative', // Needed for positioning pseudo-elements
                      border: '2px solid black', // Border around the entire slider


                      '&::before, &::after': { // Pseudo-elements for the borders
                        content: '""', // Required for pseudo-elements
                        position: 'absolute',
                        height: '100%',
                        width: 2, // Width of the border
                        backgroundColor: 'black', // Border color
                      },
                      '&::before': {
                        left: 0, // Position at the start of the track
                      },
                      '&::after': {
                        right: 0, // Position at the end of the track

                      }
                    },
                    '& .MuiSlider-rail': {
                      height: 8, // Example height
                      backgroundColor: 'white', // Example color
                      opacity: '1',
                      border: '3px solid black', // Border around the entire slider


                    },
                  }}
                />


              </div>

            )}



          </div>



        ))}

      </div>

    </div>







  )
};

export default MatchQuestions2;