

import React from 'react';




const About = ({ toggleFeaturing, isFeaturingExpanded, toggleImpressum, isImpressumExpanded, toggleDatenschutz, isDatenschutzExpanded }) => {
    return (


        <div className='aboutContainer' style={{
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'column',
            width: '65vw',
            maxWidth: '1500px'
        }}>


            <div className="about-header" onClick={toggleFeaturing}>
                <h2>Unsere Uni featuren lassen</h2>
                <div>
                    {isFeaturingExpanded ?
                        <img
                            src="/images/minus.png"
                            alt="Collapse"
                            className="centered-icon"
                            style={{ width: '20px', height: '20px' }}
                        />
                        :
                        <img
                            src="/images/plus.png"
                            alt="Collapse"
                            className="centered-icon"
                            style={{ width: '20px', height: '20px' }}
                        />
                    }
                </div>
            </div>
            {isFeaturingExpanded && (
                <div className="about-content" >


                    <h3>Nice, einfach mal Kontakt aufnehmen:</h3>
                    <p>
                        Telefon: +49 176 62293748
                        <br />
                        E-Mail: <a href="mailto:info@campusranking.de">info@campusranking.de</a>
                    </p>


                </div>
            )}

            <div className="about-header" onClick={toggleImpressum}>
                <h2>Impressum</h2>
                <div>
                    {isImpressumExpanded ?
                        <img
                            src="/images/minus.png"
                            alt="Collapse"
                            className="centered-icon"
                            style={{ width: '20px', height: '20px' }}
                        />
                        :
                        <img
                            src="/images/plus.png"
                            alt="Collapse"
                            className="centered-icon"
                            style={{ width: '20px', height: '20px' }}
                        />
                    }
                </div>
            </div>
            {isImpressumExpanded && (
                <div className="about-content" >
                    <p>Angaben gemäß § 5 TMG</p>
                    <h3>
                        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                    </h3>
                    <p>
                        Mathias Wittig
                        <br />
                        Gräfin-Adelheid-Straße 2
                        <br />
                        83352 Altenmarkt
                        <br />
                        Deutschland
                    </p>
                    <h3>Kontakt:</h3>

                    <p>
                        Telefon: +49 176 62293748
                        <br />
                        E-Mail: <a href="mailto:info@campusranking.de">info@campusranking.de</a>
                    </p>

                    <h3>Haftung für Inhalte</h3>

                    <p>
                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
                    </p>

                    <h3>Haftung für Links</h3>

                    <p>
                        Unsere Website enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    </p>

                    <h3>Urheberrecht</h3>

                    <p>
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                    </p>



                </div>
            )}



            <div className="about-header" onClick={toggleDatenschutz}>
                <h2>Datenschutz</h2>
                <div>
                    {isDatenschutzExpanded ?
                        <img
                            src="/images/minus.png"
                            alt="Collapse"
                            className="centered-icon"
                            style={{ width: '20px', height: '20px' }}
                        />
                        :
                        <img
                            src="/images/plus.png"
                            alt="Collapse"
                            className="centered-icon"
                            style={{ width: '20px', height: '20px' }}
                        />
                    }
                </div>
            </div>
            {isDatenschutzExpanded && (
                <div className="about-content">
                    <h3>Einleitung</h3>


                    <p>
                        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unserer Website und der mit ihr verbundenen Funktionen und Inhalte auf.
                    </p>
                    <h3>Verantwortlicher</h3>

                    <p>
                        Mathias Wittig
                        <br />
                        Gräfin-Adelheid-Straße 2
                        <br />
                        83352 Altenmarkt
                        <br />
                        Deutschland
                        <br />
                        <br />
                        Telefon: +49 176 62293748
                        <br />
                        E-Mail: <a href="mailto:info@campusranking.de">info@campusranking.de</a>
                    </p>



                    <h3>Arten der verarbeiteten Daten</h3>

                    <p>
                        Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen)
                    </p>
                    <h3>Zweck der Verarbeitung</h3>

                    <p>
                        Zurverfügungstellung des Onlineangebotes und seiner Funktionen
                        Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern
                    </p>

                    <h3>Verarbeitung besonderer Kategorien von Daten</h3>

                    <p>
                        Es werden keine besonderen Kategorien von Daten verarbeitet.
                    </p>

                    <h3>Nutzung der Bewertungsfunktion</h3>

                    <p>
                        Bei der Nutzung der Bewertungsfunktion werden Ihre Bewertungen sowie Ihre E-Mail-Adresse und IP-Adresse zum Zweck der Verifizierung und Sicherheit an unseren Server übermittelt. Diese Daten werden nicht an Dritte weitergegeben.
                    </p>


                    <h3>Rechte der betroffenen Personen</h3>

                    <p>
                        Sie haben das Recht, Auskunft über die Verarbeitung Ihrer Daten zu verlangen, diese zu berichtigen, zu löschen oder ihre Verarbeitung einzuschränken.
                    </p>

                    <h3>Widerrufsrecht</h3>

                    <p>
                        Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft zu widerrufen.
                    </p>

                    <h3>Löschung von Daten</h3>

                    <p>
                        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt.
                    </p>

                    <h3>Änderungen der Datenschutzerklärung</h3>

                    <p>
                        Wir behalten uns vor, die Datenschutzerklärung zu ändern, um sie an rechtliche und technische Veränderungen anzupassen.
                    </p>


                </div>

            )}



        </div>



    )
};

export default About;
